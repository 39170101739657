import React from "react";
import { graphql, Link } from "gatsby";

import Layout from "components/Layout";
import Container from "components/Container";
import Intro from "components/Intro";
import { SEO } from "components/SEO";

const NewsPosts = (props) => {
	const postList = props.data.allMarkdownRemark;

	return (
		<Layout pageName="news-archive">
			<SEO />

			<Intro className="news-archive-intro">
				<h1 className="mono">News Archive</h1>
			</Intro>

			<Container sectionName="news-archive">
				{postList.edges.map(({ node }, i) => (
					<Link to={node.fields.slug} key={i} className="link">
						<div className="post-list">
							<h1>{node.frontmatter.title}</h1>
							<span>{node.frontmatter.date}</span>
							<p>{node.excerpt}</p>
						</div>
					</Link>
				))}
			</Container>
		</Layout>
	);
};

export default NewsPosts;

export const NewsPostsQuery = graphql`
	query NewsPostsQuery {
		allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
			edges {
				node {
					fields {
						slug
					}
					excerpt(pruneLength: 100)
					frontmatter {
						date(formatString: "MMMM Do YYYY")
						title
					}
				}
			}
		}
	}
`;
